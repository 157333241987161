import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {

    return (
        <div style={{width: "100vw", height: "100vh"}} className={"center"}>
            <div className={"center"}>
                <h1 style={{textAlign: "center"}}>Nie ma takiej strony
                <br/>
                    <Link to={"/"}>Kliknij, żeby powrócić na stronę główną</Link>
                </h1>
            </div>
        </div>
    );
};

export default NotFound;

import React, {useState} from 'react';
import Panel from "../repeated/Panel";
import Footer from "../repeated/Footer";
import {NavLink} from "react-router-dom";
import {isMobile} from "react-device-detect";
import MobilePanel from "../repeated/MobilePanel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


const Sale = () => {

    if (document.getElementById("body").classList.length !== 0) {
        document.getElementById("body").classList.remove("start", "center")
    }

    const [borderStyle, setBorderStyle] = useState("none");
    const [borderColor, setBorderColor] = useState("");
    const [borderRadius, setBorderRadius] = useState(0)
    const [borderWidth, setBorderWidth] = useState(0)

    let style = {
        borderStyle: borderStyle,
        borderColor: borderColor,
        borderRadius: borderRadius,
        borderWidth: borderWidth,
        height: "5vh",
        textDecoration: 'none',
        width: "30vw",
        color: '#0F138B'
    }

    const enter = () => {
        setBorderStyle("solid")
        setBorderColor("black")
        setBorderRadius(5)
        setBorderWidth(2)
    }

    const left = () => {
        setBorderStyle("none")
        setBorderColor("")
        setBorderRadius(0)
        setBorderWidth(0)
    }


    if (isMobile) {
        return (
            <>
                <MobilePanel/>
                <div className={"mBottom"}>
                    <div style={{paddingTop: "2vh"}}>
                        <NavLink to={"/apartments/graniczna"} className={"center"} style={{textDecoration: 'none'}}>
                            <div onMouseEnter={enter} onMouseLeave={left} style={{color: '#0F138B'}}
                                 className={"center"}>
                                <h4 className={"center"} style={{textDecoration: 'none'}}>Aktualna oferta - wejdź</h4>
                            </div>
                        </NavLink>
                    </div>
                    <div className={"bottom-size center"}>
                        <div style={{borderStyle: "solid", borderWidth: 3, borderColor: "grey", padding: '2vh'}}>
                            <strong className={"center"}
                                    style={{padding: '1vh', color: "#778899", fontSize: "medium"}}> Apartamenty
                                Graniczna 6.
                                Legionowo </strong>
                            <div>
                                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={2000} showArrows={false} showStatus={false} showIndicators={false} autoFocus={true} width={"80vw"} emulateTouch={false} swipeable={false} stopOnHover={false}>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grp1.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grp2.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grz1.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grz2.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grw1.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grw2.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grw3.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grw4.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grw5.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grw6.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grw7.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grw8.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                    <div>
                                        <img src="https://arkadiadevelopment.pl/img/grw9.webP"  alt="" className="represent-photo-small"/>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    } else {

        if (document.getElementById("body").style.length === 0) {
            document.getElementById("body").style.overflow = "hidden"
        }

        return (
            <>
                <Panel/>
                <NavLink to={"/apartments/graniczna"} className={"center"} style={{textDecoration: 'none'}}>
                    <div onMouseEnter={enter} onMouseLeave={left} style={style} className={"center"}>
                        <h4 className={"center"} style={{textDecoration: 'none'}}>Aktualna oferta - wejdź</h4>
                    </div>
                </NavLink>
                <div className={"bottom-size center"}>
                    <div style={{borderStyle: "solid", borderWidth: 3, borderColor: "grey", padding: '2vh'}}>
                        <strong className={"center"}
                                style={{padding: '1vh', color: "#778899", fontSize: "larger"}}> Apartamenty Graniczna 6.
                            Legionowo </strong>
                        <div>
                            <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={2000} showArrows={false} showStatus={false} showIndicators={false} autoFocus={true} width={"65vw"} emulateTouch={false} swipeable={false} stopOnHover={false}>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grp1.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grp2.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grz1.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grz2.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grw1.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grw2.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grw3.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grw4.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grw5.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grw6.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grw7.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grw8.webP"  alt="" className="represent-photo-small"/>
                                </div>
                                <div>
                                    <img src="https://arkadiadevelopment.pl/img/grw9.webP"  alt="" className="represent-photo-small"/>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }

};

export default Sale;

import React from 'react';

const Footer = () => {


    return (
        <div className={"centre"}>
            <p className={"footer txtFont"}>
                {`Arkadia Development GMBH ${new Date().getFullYear()}`}
            </p>
        </div>
    );
};

export default Footer;
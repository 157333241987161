import React from 'react';
import Panel from "../repeated/Panel";
import Footer from "../repeated/Footer";
import {isMobile} from "react-device-detect";
import MobilePanel from "../repeated/MobilePanel";

const About = () => {

    if (isMobile) {
        return (
            <>
                <MobilePanel/>
                <div className={"center"} style={{paddingTop: "2vh", marginBottom: "1vh"}}>
                        <strong className={"center"} style={{
                            color: "#778899",
                            fontSize: "medium",
                            width: "80vw",
                            textAlign: "center"

                        }}>Arkadia Development - do budowania podchodzimy z pasją oraz z ogromnym zaangażowaniem w każdy
                            etap prac. Od wielu lat budujemy z ogromną dbałością o każdy nawet najmniejszy szczegół.
                            Współpracujemy z
                            najlepszymi biurami architektonicznymi, dzięki czemu nasze projekty są funkcjonalne i
                            nowoczesne.
                            Kładziemy nacisk na trwałość oraz solidność, dlatego używamy najwyższej jakości materiałów
                            budowlanych. Pracujemy tylko z doświadczonymi podwykonawcami, dzięki czemu jesteśmy pewni
                            jaki
                            będzie efekt końcowy. Stawiamy na komfort, wygodę oraz wyjątkowy klimat naszych projektów.
                            Serdecznie zapraszamy do zapoznania się z naszą aktualną ofertą.</strong>
                </div>
                <br/>
                <img src={"https://arkadiadevelopment.pl/img/about.webP"} alt={""} style={{width: "100vw"}}/>
                <Footer/>
            </>
        )
    } else {

        if (document.getElementById("body").style.length === 0) {
            document.getElementById("body").style.overflow = "hidden"
        }

        return (
            <>
                <Panel/>
                <div className={"bottom-size center"}>
                    <div className="represent-photo-large center">
                        <img src={"https://arkadiadevelopment.pl/img/about.webP"}
                             style={{width: '55vw', height: '55vh', marginRight: '10vw'}} alt={""}/>
                        <strong className={"center"} style={{
                            borderStyle: "none",
                            borderColor: "black",
                            color: "#778899",
                            fontSize: "medium",
                            borderRadius: 5,
                            width: "55vw",
                            height: "55vh",
                            padding: "2vh"

                        }}>Arkadia Development - do budowania podchodzimy z pasją oraz z ogromnym zaangażowaniem w każdy
                            etap prac. Od wielu lat budujemy z ogromną dbałością o każdy nawet najmniejszy szczegół.
                            Współpracujemy z
                            najlepszymi biurami architektonicznymi, dzięki czemu nasze projekty są funkcjonalne i
                            nowoczesne.
                            Kładziemy nacisk na trwałość oraz solidność, dlatego używamy najwyższej jakości materiałów
                            budowlanych. Pracujemy tylko z doświadczonymi podwykonawcami, dzięki czemu jesteśmy pewni
                            jaki
                            będzie efekt końcowy. Stawiamy na komfort, wygodę oraz wyjątkowy klimat naszych projektów.
                            Serdecznie zapraszamy do zapoznania się z naszą aktualną ofertą.</strong>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default About;

import Panel from "../repeated/Panel";
import Footer from "../repeated/Footer";
import {isMobile} from "react-device-detect";
import MobilePanel from "../repeated/MobilePanel";

const GIPH = () => {

    if (isMobile) {
        return (
            <div className="mContact center">
                <img src={"https://arkadiadevelopment.pl/img/phone mobile.gif"} alt={""} className={"mContact center"} style={{paddingLeft : "10vw"}}/>
            </div>
        );
    } else {
        return (
            <div className="center">
                <img src={"https://arkadiadevelopment.pl/img/larger phone 1.gif"} alt={""}/>
            </div>
        );
    }

}

const Contact = () => {

    if (isMobile) {
        return (
            <>
                <MobilePanel/>
                <GIPH/>
                <Footer/>
            </>
        );
    } else {

        if (document.getElementById("body").style.length === 0) {
            document.getElementById("body").style.overflow = "hidden"
        }

        return (
            <>
                <Panel/>
                <GIPH/>
                <Footer/>
            </>
        );
    }
}

export default Contact;

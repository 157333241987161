import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {isMobile} from "react-device-detect";


const Element = ({adress, type, source, text, extra}) => {

    const [borderStyle, setBorderStyle] = useState("none");
    const [borderColor, setBorderColor] = useState("");
    const [borderRadius, setBorderRadius] = useState(0)
    const [borderWidth, setBorderWidth] = useState(0)

    let style = {
        borderStyle: borderStyle,
        borderColor: borderColor,
        borderRadius: borderRadius,
        borderWidth: borderWidth,
        paddingTop: "1vh",
        paddingBottom: "1vh",
        paddingLeft: "1vw",
        paddingRight: "1vw",
        marginBottom: "2vh"
    }

    const enter = () => {
        setBorderStyle("solid")
        setBorderColor("grey")
        setBorderRadius(5)
        setBorderWidth(2)
    }

    const left = () => {
        setBorderStyle("none")
        setBorderColor("")
        setBorderRadius(0)
        setBorderWidth(0)
    }

    let device = ""

    if(isMobile){
        device = "M"
    }


    if (extra !== false) {
        return (
            <>
                <div style={style} className={device==="M" ? "mElements":"element"}>
                    <NavLink onMouseEnter={enter} onMouseLeave={left}
                             style={{textDecoration: 'none'}}  to={adress}><strong className={"txt center"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className={`bi ${type}`} viewBox="0 0 16 16">
                            <path d={source}/>
                            <path d={extra}/>
                        </svg>
                        {"   "} {text}</strong></NavLink>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div style={style} className={device==="M" ? "mElements":"element"}>
                    <NavLink onMouseEnter={enter} onMouseLeave={left}
                             style={{textDecoration: 'none'}}   to={adress}><strong className={"txt center"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className={`bi ${type}`} viewBox="0 0 16 16">
                            <path d={source}/>
                        </svg>
                        {"   "} {text}</strong></NavLink>
                </div>
            </>
        )
    }
}
export default Element;
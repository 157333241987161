import React from 'react';
import Panel from "../repeated/Panel";
import Footer from "../repeated/Footer";
import {NavLink} from "react-router-dom";
import {isMobile} from "react-device-detect";
import MobilePanel from "../repeated/MobilePanel";


const Graniczna = () => {

if (isMobile) {
    return (
        <>
            <MobilePanel/>
            <div>
            <div className={"center"}>
                <h3 className={"txtFont"} style={{fontSize: "large", marginTop: "2vh"}}>Apartamenty ul. Graniczna 6 | Legionowo</h3>
            </div>
            <div style={{paddingTop: '2vh'}}>
                <div className={"center"}>
                    <p className={"txtFont"} style={{fontSize: "medium", width: "90vw", textAlign: "center"}}>Kompleks apartamentów Graniczna 6 składa sie z:
                        dwóch budynków po
                        cztery segmenty oraz dwóch
                        po dwa segmenty - łącznie dwanaście apartamentów. Inwestycja zlokalizowana jest w bardzo dobrze
                        położonym miejscu Legionowa. W bezpośrednim sąsiedztwie niska zabudowa, szybki dojazd do
                        głównej drogi dojazdowej a zarazem cicha i spokojna okolica. W pobliżu sklepy, szkoły,
                        przedszkola oraz komunikacja miejska. Segmenty składają się z trzech kondygnacji o łącznej
                        powierzchni 96 m<sup>2</sup>. Do każdego segmentu należy miejsce parkingowe oraz
                        ogródek. <br/>
                        <br/>

                        <strong>W celu zapoznania się z rzutami danego segmentu, należy kliknąć na niego na mapie poniżej</strong><br/>
                        <br/>
                        <p>Legenda mapy: <br/>
                            Kolor <strong style={{color: "red"}}>czerwony</strong> - segment sprzedany <br/>
                            Kolor <strong style={{color: "blue"}}>niebieski</strong> - segment zarezerwowany <br/>
                            Kolor <strong style={{color: "gray"}}>szary</strong> - segment wolny
                        </p>
                    </p>

                </div>
                <svg version="1.1" id="Warstwa_2_00000099627894053841491110000014474692581496360619_"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595.3 841.9"
                     style={{enableBackground: "new 0 0 595.3 841.9;"}}>
                    <g id="Warstwa_1_m">
                        <polygon className="st0" points="311.5,562.4 55.3,563 57.9,58.3 546.9,11.1 547.8,564.6 	"/>
                        <polygon className="st1" points="186.5,507.5 186.5,501.4 167.3,501.4 167.2,105.1 436.1,105.3 436.1,501.2 414.9,501.5 414.9,507.9"/>
                        <rect x="118.2" y="443.8" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="387.1" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="275.8" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="219.1" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="162.5" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="105.8" className="st2" width="48.5" height="56.7"/>
                        <rect x="436.6" y="443.8" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="387.1" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="275.8" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="219.1" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="162.5" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="105.8" className="st2" width="48.5" height="56.7"/>
                        <rect x="173.9" y="383.1" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="173.9" y1="382.8" x2="186.4" y2="437.2"/>
                        <line className="st3" x1="173.9" y1="436.8" x2="186.4" y2="382.8"/>
                        <rect x="173.9" y="446.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="173.9" y1="499.9" x2="186.4" y2="445.8"/>
                        <rect x="414.8" y="383.3" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="383" x2="427.2" y2="437.4"/>
                        <line className="st3" x1="414.8" y1="437" x2="427.2" y2="383"/>
                        <rect x="414.8" y="446" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="445.6" x2="427.2" y2="500.1"/>
                        <line className="st3" x1="414.8" y1="499.7" x2="427.2" y2="445.6"/>
                        <rect x="414.8" y="287.1" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="286.8" x2="427.2" y2="341.2"/>
                        <line className="st3" x1="414.8" y1="340.8" x2="427.2" y2="286.8"/>
                        <rect x="414.8" y="106.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="105.8" x2="427.2" y2="160.3"/>
                        <line className="st3" x1="414.8" y1="159.9" x2="427.2" y2="105.8"/>
                        <rect x="414.8" y="167.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="166.8" x2="427.2" y2="221.3"/>
                        <line className="st3" x1="414.8" y1="220.9" x2="427.2" y2="166.8"/>
                        <rect x="414.8" y="221.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="220.9" x2="427.2" y2="275.3"/>
                        <line className="st3" x1="414.8" y1="274.9" x2="427.2" y2="220.9"/>
                        <rect x="176.8" y="287.1" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="176.8" y1="286.8" x2="189.2" y2="341.2"/>
                        <line className="st3" x1="176.8" y1="340.8" x2="189.2" y2="286.8"/>
                        <rect x="176.8" y="106.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="176.8" y1="105.8" x2="189.2" y2="160.3"/>
                        <line className="st3" x1="176.8" y1="159.9" x2="189.2" y2="105.8"/>
                        <rect x="176.8" y="167.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="176.8" y1="166.8" x2="189.2" y2="221.3"/>
                        <line className="st3" x1="176.8" y1="220.9" x2="189.2" y2="166.8"/>
                        <rect x="176.8" y="221.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="176.8" y1="220.9" x2="189.2" y2="275.3"/>
                        <line className="st3" x1="176.8" y1="274.9" x2="189.2" y2="220.9"/>
                        <rect x="436.6" y="508.9" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="436.6" y1="508.6" x2="449" y2="563"/>
                        <line className="st3" x1="436.6" y1="562.6" x2="449" y2="508.6"/>
                        <path className="st4" d="M455.9,563"/>
                        <rect x="449" y="508.9" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="449" y1="508.6" x2="461.5" y2="563"/>
                        <line className="st3" x1="449" y1="562.6" x2="461.5" y2="508.6"/>
                        <path className="st4" d="M469.9,563"/>
                        <rect x="141.8" y="508.3" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="141.8" y1="508" x2="154.2" y2="562.4"/>
                        <line className="st3" x1="141.8" y1="562" x2="154.2" y2="508"/>
                        <path className="st4" d="M125.4,562.4"/>
                        <rect x="154.2" y="508.3" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="154.2" y1="508" x2="166.7" y2="562.4"/>
                        <line className="st3" x1="154.2" y1="562" x2="166.7" y2="508"/>
                        <path className="st4" d="M139.3,562.4"/>
                        <path className="st4" d="M166.7,519.7"/>
                        <line className="st3" x1="173.9" y1="445.8" x2="186.4" y2="500.3"/>
                        <polyline className="st4" points="166.7,497.3 166.7,500.7 166.7,500.8 	"/>
                        <path className="st4" d="M164.2,508.3"/>
                        <line className="st3" x1="0" y1="583.1" x2="595.3" y2="583.1"/>
                        <line className="st3" x1="0" y1="624.3" x2="595.3" y2="624.3"/>
                        <polyline className="st4" points="0,603.7 1.2,603.7 43.4,603.7 	"/>
                        <line className="st3" x1="58" y1="603.7" x2="100.3" y2="603.7"/>
                        <line className="st3" x1="113.7" y1="603.7" x2="156" y2="603.7"/>
                        <line className="st3" x1="168.7" y1="603.7" x2="211" y2="603.7"/>
                        <line className="st3" x1="225.1" y1="603.7" x2="267.3" y2="603.7"/>
                        <line className="st3" x1="281.6" y1="603.7" x2="323.8" y2="603.7"/>
                        <line className="st3" x1="335.8" y1="603.7" x2="378" y2="603.7"/>
                        <line className="st3" x1="389.9" y1="603.7" x2="432.1" y2="603.7"/>
                        <line className="st3" x1="445" y1="603.7" x2="487.2" y2="603.7"/>
                        <line className="st3" x1="498.8" y1="603.7" x2="541.1" y2="603.7"/>
                        <line className="st3" x1="553" y1="603.7" x2="595.3" y2="603.7"/>
                        <path className="st4" d="M59.5,72.9"/>
                        <path className="st5" d="M186.8,522.5c0.1,0,0,40,0,40h-20.1V522h20.1"/>
                        <polygon className="st5" points="436.9,523.2 414.8,523.1 414.6,563 436.6,563 	"/>
                        <path className="st4" d="M425.7,537.8"/>
                        <line className="st3" x1="166.7" y1="508.3" x2="166.7" y2="522"/>
                        <path className="st4" d="M184.5,508.3"/>
                        <path className="st4" d="M285.9,562"/>
                        <path className="st1" d="M167.2,508.3v13.1V508.3z"/>
                        <path className="st1" d="M167.2,509.6"/>
                        <path className="st1" d="M167.2,509.9"/>
                        <polyline className="st1" points="167.2,508.3 436.1,508.6 436.1,522.7 414.1,522.7 414,562.2 187.4,561.7 187.4,521.4 168,521.4
		167.4,508.7 	"/>
                        <rect x="284.6" y="633.9" className="st6" width="61.2" height="15.3"/>
                        <text transform="matrix(1 0 0 1 292.4134 652.6458)" className="st7 st8">Graniczna</text>
                        <rect x="453.8" y="294.1" className="st6" width="14.1" height="17.6"/>
                        <rect x="453" y="238.9" className="st6" width="16" height="18.8"/>
                        <rect x="454.5" y="181.8" className="st6" width="14.4" height="18.7"/>
                        <rect x="455.2" y="125" className="st6" width="13.1" height="19.9"/>
                        <rect x="136.1" y="125.8" className="st6" width="18.2" height="20.3"/>
                        <rect x="136.6" y="181.6" className="st6" width="16.6" height="18.4"/>
                        <rect x="141.5" y="238.1" className="st6" width="6.9" height="19.6"/>
                        <rect x="138.4" y="295" className="st6" width="9.4" height="18.3"/>
                        <rect x="136.4" y="403.8" className="st6" width="15.1" height="21.9"/>
                        <rect x="138" y="463.1" className="st6" width="13.6" height="17.4"/>
                    </g>
                    <path className="st4" d="M414.8,501.5"/>
                    <rect x="454.5" y="462.3" className="st6" width="13.7" height="20.2"/>
                    <rect x="455" y="407" className="st6" width="11.6" height="16.9"/>
                    <polyline className="st4" points="485.1,500.4 519.7,500.2 547.8,500.1 "/>
                    <line className="st3" x1="485.1" y1="443.8" x2="547.6" y2="443.8"/>
                    <line className="st3" x1="436.1" y1="357.8" x2="547.8" y2="357.8"/>
                    <line className="st3" x1="485.1" y1="275.8" x2="547.3" y2="275.8"/>
                    <line className="st3" x1="485.1" y1="219.1" x2="547.3" y2="219.1"/>
                    <line className="st3" x1="485.1" y1="162.5" x2="547.8" y2="162.5"/>
                    <line className="st3" x1="309.6" y1="106.2" x2="309.6" y2="32.9"/>
                    <line className="st3" x1="118.2" y1="162.5" x2="57.4" y2="162.5"/>
                    <line className="st3" x1="118.2" y1="219.1" x2="57.4" y2="219.1"/>
                    <line className="st3" x1="118.2" y1="275.8" x2="56.8" y2="275.3"/>
                    <line className="st3" x1="167.3" y1="360.3" x2="56.3" y2="360.3"/>
                    <line className="st3" x1="118.2" y1="443.8" x2="56.3" y2="443.8"/>
                    <line className="st3" x1="118.2" y1="500.4" x2="55.3" y2="500.3"/>
                    <NavLink to="/graniczna/lokale/6" style={{textDecoration: "none"}}>
                        <g id="znak_6">
                            <polygon className="st9" points="475.3,478.3 447.4,478.3 447.4,475.7 447.4,462.3 475.3,462.3 475.3,475.7 	"/>
                            <rect x="447.4" y="474.8" className="st10" width="27.9" height="3.5"/>
                            <text transform="matrix(1 0 0 1 457.8186 473.6172)" className="st11 st7 st12">6</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6A" style={{textDecoration: "none"}}>
                        <g id="znak_6A">
                            <polygon className="st9" points="474.3,423 446.4,423 446.4,420.4 446.4,407 474.3,407 474.3,420.4 	"/>
                            <rect x="446.4" y="419.5" className="st10" width="27.9" height="3.5"/>
                            <text transform="matrix(1 0 0 1 452.7427 417.3151)" className="st11 st7 st12">6.A</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6B" style={{textDecoration: "none"}}>
                        <g id="znak_6B">
                            <polygon className="st9" points="474.8,314.6 446.9,314.6 446.9,311.9 446.9,298.5 474.8,298.5 474.8,311.9 	"/>
                            <rect x="446.9" y="311.1" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.B" transform="matrix(1 0 0 1 453.215 308.8336)" className="st11 st7 st12">6.B</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6C" style={{textDecoration: "none"}}>
                        <g id="znak_6C">
                            <polygon className="st9" points="475.7,255.9 447.8,255.9 447.8,253.3 447.8,239.8 475.7,239.8 475.7,253.3 	"/>
                            <rect x="447.8" y="252.4" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.C" transform="matrix(1 0 0 1 454.125 250.1886)" className="st11 st7 st12">6.C</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6D" style={{textDecoration: "none"}}>
                        <g id="znak_6D">
                            <polygon className="st9" points="474.8,198.9 446.9,198.9 446.9,196.2 446.9,182.8 474.8,182.8 474.8,196.2 	"/>
                            <rect x="446.9" y="195.4" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.D" transform="matrix(1 0 0 1 453.215 193.1385)" className="st11 st7 st12">6.D</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6E" style={{textDecoration: "none"}}>
                        <g id="znak_6E">
                            <polygon className="st9" points="474.8,141.3 446.9,141.3 446.9,138.7 446.9,125.2 474.8,125.2 474.8,138.7 	"/>
                            <rect x="446.9" y="137.8" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.E" transform="matrix(1 0 0 1 453.215 135.5635)" className="st11 st7 st12">6.E</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6F" style={{textDecoration: "none"}}>
                        <g id="znak_6F">
                            <polygon className="st9" points="157.1,141.3 129.2,141.3 129.2,138.7 129.2,125.2 157.1,125.2 157.1,138.7 	"/>
                            <rect x="129.2" y="137.9" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.F" transform="matrix(1 0 0 1 135.5 135.5635)" className="st11 st7 st12">6.F</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6G" style={{textDecoration: "none"}}>
                        <g id="znak_6G">
                            <polygon className="st9" points="159.2,201.9 131.3,201.9 131.3,199.3 131.3,185.8 159.2,185.8 159.2,199.3 	"/>
                            <rect x="131.3" y="198.4" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.G" transform="matrix(1 0 0 1 137.625 196.1586)" className="st11 st7 st12">6.G</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6H" style={{textDecoration: "none"}}>
                        <g id="znak_6H">
                            <polygon className="st9" points="158.9,256.3 131,256.3 131,253.7 131,240.2 158.9,240.2 158.9,253.7 	"/>
                            <rect x="131" y="252.8" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.H" transform="matrix(1 0 0 1 137.34 250.5735)" className="st11 st7 st12">6.H</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6J" style={{textDecoration: "none"}}>
                        <g id="znak_6J">
                            <polygon className="st9" points="159.2,314.6 131.3,314.6 131.3,311.9 131.3,298.5 159.2,298.5 159.2,311.9 	"/>
                            <rect x="131.3" y="311.1" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.J" transform="matrix(1 0 0 1 137.625 308.8336)" className="st11 st7 st12">6.J</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6K" style={{textDecoration: "none"}}>
                        <g id="znak_6K">
                            <polygon className="st9" points="159.2,425.9 131.3,425.9 131.3,423.2 131.3,409.8 159.2,409.8 159.2,423.2 	"/>
                            <rect x="131.3" y="422.4" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.K" transform="matrix(1 0 0 1 137.625 420.15)" className="st11 st7 st12">6.K</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6L" style={{textDecoration: "none"}}>
                        <g id="znak_6L">
                            <polygon className="st9" points="159.2,481.1 131.3,481.1 131.3,478.5 131.3,465 159.2,465 159.2,478.5 	"/>
                            <rect x="131.3" y="477.6" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.L" transform="matrix(1 0 0 1 137.625 475.3636)" className="st11 st7 st12">6.L</text>
                        </g>
                    </NavLink>
                </svg>
                <div className={"center"}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.0670706052088!2d20.923470776961345!3d52.387334446042935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eb912dfb60991%3A0x5cfe6ab2a1b71202!2sApartamenty%20Graniczna%206!5e0!3m2!1spl!2spl!4v1693517124171!5m2!1spl!2spl" style={{border:0, width: "100vw", height: "31vh",}} allowFullScreen="true" loading="lazy" className={"center"} title={"granicznaMobile"}/>
                </div>
            </div>
            </div>
            <Footer/>
        </>

    );
} else {

    if (document.getElementById("body").style.length === 0) {
        document.getElementById("body").style.overflow = "hidden"
    }

    return (
        <>
            <Panel/>
            <div className={"center"}>
                <h3 className={"txtFont"} style={{fontSize: "x-large"}}>Apartamenty ul. Graniczna 6 | Legionowo</h3>
            </div>
            <div className={"center"} style={{paddingTop: '2vh'}}>
                <div style={{width: '44vw', height: '30vh', paddingLeft: '4vw', paddingBottom: '22vh', paddingTop: '6vh'}} className={"center"}>
                    <p className={"txtFont"} style={{fontSize: "medium"}}>Kompleks apartamentów Graniczna 6 składa sie z:
                        dwóch budynków po
                        cztery segmenty oraz dwóch
                        po dwa segmenty - łącznie dwanaście apartamentów. Inwestycja zlokalizowana jest w bardzo dobrze
                        położonym miejscu Legionowa. W bezpośrednim sąsiedztwie niska zabudowa, szybki dojazd do
                        głównej drogi dojazdowej a zarazem cicha i spokojna okolica. W pobliżu sklepy, szkoły,
                        przedszkola oraz komunikacja miejska. Segmenty składają się z trzech kondygnacji o łącznej
                        powierzchni 96,25 m<sup>2</sup>. Do każdego segmentu należy miejsce parkingowe oraz
                        ogródek. <br/>
                        <br/>

                        <strong>W celu zapoznania się z rzutami danego segmentu, należy kliknąć na niego na mapie po
                            prawej
                            stronie.</strong><br/>
                        <br/>
                        <p>Legenda mapy: <br/>
                            Kolor <strong style={{color: "red"}}>czerwony</strong> - segment sprzedany <br/>
                            Kolor <strong style={{color: "blue"}}>niebieski</strong> - segment zarezerwowany <br/>
                            Kolor <strong style={{color: "gray"}}>szary</strong> - segment wolny
                        </p>
                    </p>

                </div>
                <svg version="1.1" id="Warstwa_2_00000099627894053841491110000014474692581496360619_"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595.3 841.9"
                     style={{enableBackground: "new 0 0 595.3 841.9;", height: '85vh', width: '55vw'}}>
                    <g id="Warstwa_1_m">
                        <polygon className="st0" points="311.5,562.4 55.3,563 57.9,58.3 546.9,11.1 547.8,564.6 	"/>
                        <polygon className="st1" points="186.5,507.5 186.5,501.4 167.3,501.4 167.2,105.1 436.1,105.3 436.1,501.2 414.9,501.5 414.9,507.9"/>
                        <rect x="118.2" y="443.8" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="387.1" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="275.8" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="219.1" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="162.5" className="st2" width="48.5" height="56.6"/>
                        <rect x="118.2" y="105.8" className="st2" width="48.5" height="56.7"/>
                        <rect x="436.6" y="443.8" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="387.1" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="275.8" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="219.1" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="162.5" className="st2" width="48.5" height="56.6"/>
                        <rect x="436.6" y="105.8" className="st2" width="48.5" height="56.7"/>
                        <rect x="173.9" y="383.1" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="173.9" y1="382.8" x2="186.4" y2="437.2"/>
                        <line className="st3" x1="173.9" y1="436.8" x2="186.4" y2="382.8"/>
                        <rect x="173.9" y="446.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="173.9" y1="499.9" x2="186.4" y2="445.8"/>
                        <rect x="414.8" y="383.3" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="383" x2="427.2" y2="437.4"/>
                        <line className="st3" x1="414.8" y1="437" x2="427.2" y2="383"/>
                        <rect x="414.8" y="446" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="445.6" x2="427.2" y2="500.1"/>
                        <line className="st3" x1="414.8" y1="499.7" x2="427.2" y2="445.6"/>
                        <rect x="414.8" y="287.1" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="286.8" x2="427.2" y2="341.2"/>
                        <line className="st3" x1="414.8" y1="340.8" x2="427.2" y2="286.8"/>
                        <rect x="414.8" y="106.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="105.8" x2="427.2" y2="160.3"/>
                        <line className="st3" x1="414.8" y1="159.9" x2="427.2" y2="105.8"/>
                        <rect x="414.8" y="167.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="166.8" x2="427.2" y2="221.3"/>
                        <line className="st3" x1="414.8" y1="220.9" x2="427.2" y2="166.8"/>
                        <rect x="414.8" y="221.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="414.8" y1="220.9" x2="427.2" y2="275.3"/>
                        <line className="st3" x1="414.8" y1="274.9" x2="427.2" y2="220.9"/>
                        <rect x="176.8" y="287.1" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="176.8" y1="286.8" x2="189.2" y2="341.2"/>
                        <line className="st3" x1="176.8" y1="340.8" x2="189.2" y2="286.8"/>
                        <rect x="176.8" y="106.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="176.8" y1="105.8" x2="189.2" y2="160.3"/>
                        <line className="st3" x1="176.8" y1="159.9" x2="189.2" y2="105.8"/>
                        <rect x="176.8" y="167.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="176.8" y1="166.8" x2="189.2" y2="221.3"/>
                        <line className="st3" x1="176.8" y1="220.9" x2="189.2" y2="166.8"/>
                        <rect x="176.8" y="221.2" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="176.8" y1="220.9" x2="189.2" y2="275.3"/>
                        <line className="st3" x1="176.8" y1="274.9" x2="189.2" y2="220.9"/>
                        <rect x="436.6" y="508.9" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="436.6" y1="508.6" x2="449" y2="563"/>
                        <line className="st3" x1="436.6" y1="562.6" x2="449" y2="508.6"/>
                        <path className="st4" d="M455.9,563"/>
                        <rect x="449" y="508.9" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="449" y1="508.6" x2="461.5" y2="563"/>
                        <line className="st3" x1="449" y1="562.6" x2="461.5" y2="508.6"/>
                        <path className="st4" d="M469.9,563"/>
                        <rect x="141.8" y="508.3" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="141.8" y1="508" x2="154.2" y2="562.4"/>
                        <line className="st3" x1="141.8" y1="562" x2="154.2" y2="508"/>
                        <path className="st4" d="M125.4,562.4"/>
                        <rect x="154.2" y="508.3" className="st1" width="12.4" height="54.1"/>
                        <line className="st3" x1="154.2" y1="508" x2="166.7" y2="562.4"/>
                        <line className="st3" x1="154.2" y1="562" x2="166.7" y2="508"/>
                        <path className="st4" d="M139.3,562.4"/>
                        <path className="st4" d="M166.7,519.7"/>
                        <line className="st3" x1="173.9" y1="445.8" x2="186.4" y2="500.3"/>
                        <polyline className="st4" points="166.7,497.3 166.7,500.7 166.7,500.8 	"/>
                        <path className="st4" d="M164.2,508.3"/>
                        <line className="st3" x1="0" y1="583.1" x2="595.3" y2="583.1"/>
                        <line className="st3" x1="0" y1="624.3" x2="595.3" y2="624.3"/>
                        <polyline className="st4" points="0,603.7 1.2,603.7 43.4,603.7 	"/>
                        <line className="st3" x1="58" y1="603.7" x2="100.3" y2="603.7"/>
                        <line className="st3" x1="113.7" y1="603.7" x2="156" y2="603.7"/>
                        <line className="st3" x1="168.7" y1="603.7" x2="211" y2="603.7"/>
                        <line className="st3" x1="225.1" y1="603.7" x2="267.3" y2="603.7"/>
                        <line className="st3" x1="281.6" y1="603.7" x2="323.8" y2="603.7"/>
                        <line className="st3" x1="335.8" y1="603.7" x2="378" y2="603.7"/>
                        <line className="st3" x1="389.9" y1="603.7" x2="432.1" y2="603.7"/>
                        <line className="st3" x1="445" y1="603.7" x2="487.2" y2="603.7"/>
                        <line className="st3" x1="498.8" y1="603.7" x2="541.1" y2="603.7"/>
                        <line className="st3" x1="553" y1="603.7" x2="595.3" y2="603.7"/>
                        <path className="st4" d="M59.5,72.9"/>
                        <path className="st5" d="M186.8,522.5c0.1,0,0,40,0,40h-20.1V522h20.1"/>
                        <polygon className="st5" points="436.9,523.2 414.8,523.1 414.6,563 436.6,563 	"/>
                        <path className="st4" d="M425.7,537.8"/>
                        <line className="st3" x1="166.7" y1="508.3" x2="166.7" y2="522"/>
                        <path className="st4" d="M184.5,508.3"/>
                        <path className="st4" d="M285.9,562"/>
                        <path className="st1" d="M167.2,508.3v13.1V508.3z"/>
                        <path className="st1" d="M167.2,509.6"/>
                        <path className="st1" d="M167.2,509.9"/>
                        <polyline className="st1" points="167.2,508.3 436.1,508.6 436.1,522.7 414.1,522.7 414,562.2 187.4,561.7 187.4,521.4 168,521.4
		167.4,508.7 	"/>
                        <rect x="284.6" y="633.9" className="st6" width="61.2" height="15.3"/>
                        <text transform="matrix(1 0 0 1 292.4134 652.6458)" className="st7 st8">Graniczna</text>
                        <rect x="453.8" y="294.1" className="st6" width="14.1" height="17.6"/>
                        <rect x="453" y="238.9" className="st6" width="16" height="18.8"/>
                        <rect x="454.5" y="181.8" className="st6" width="14.4" height="18.7"/>
                        <rect x="455.2" y="125" className="st6" width="13.1" height="19.9"/>
                        <rect x="136.1" y="125.8" className="st6" width="18.2" height="20.3"/>
                        <rect x="136.6" y="181.6" className="st6" width="16.6" height="18.4"/>
                        <rect x="141.5" y="238.1" className="st6" width="6.9" height="19.6"/>
                        <rect x="138.4" y="295" className="st6" width="9.4" height="18.3"/>
                        <rect x="136.4" y="403.8" className="st6" width="15.1" height="21.9"/>
                        <rect x="138" y="463.1" className="st6" width="13.6" height="17.4"/>
                    </g>
                    <path className="st4" d="M414.8,501.5"/>
                    <rect x="454.5" y="462.3" className="st6" width="13.7" height="20.2"/>
                    <rect x="455" y="407" className="st6" width="11.6" height="16.9"/>
                    <polyline className="st4" points="485.1,500.4 519.7,500.2 547.8,500.1 "/>
                    <line className="st3" x1="485.1" y1="443.8" x2="547.6" y2="443.8"/>
                    <line className="st3" x1="436.1" y1="357.8" x2="547.8" y2="357.8"/>
                    <line className="st3" x1="485.1" y1="275.8" x2="547.3" y2="275.8"/>
                    <line className="st3" x1="485.1" y1="219.1" x2="547.3" y2="219.1"/>
                    <line className="st3" x1="485.1" y1="162.5" x2="547.8" y2="162.5"/>
                    <line className="st3" x1="309.6" y1="106.2" x2="309.6" y2="32.9"/>
                    <line className="st3" x1="118.2" y1="162.5" x2="57.4" y2="162.5"/>
                    <line className="st3" x1="118.2" y1="219.1" x2="57.4" y2="219.1"/>
                    <line className="st3" x1="118.2" y1="275.8" x2="56.8" y2="275.3"/>
                    <line className="st3" x1="167.3" y1="360.3" x2="56.3" y2="360.3"/>
                    <line className="st3" x1="118.2" y1="443.8" x2="56.3" y2="443.8"/>
                    <line className="st3" x1="118.2" y1="500.4" x2="55.3" y2="500.3"/>
                    <NavLink to="/graniczna/lokale/6" style={{textDecoration: "none"}}>
                        <g id="znak_6">
                            <polygon className="st9" points="475.3,478.3 447.4,478.3 447.4,475.7 447.4,462.3 475.3,462.3 475.3,475.7 	"/>
                            <rect x="447.4" y="474.8" className="st10" width="27.9" height="3.5"/>
                            <text transform="matrix(1 0 0 1 457.8186 473.6172)" className="st11 st7 st12">6</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6A" style={{textDecoration: "none"}}>
                        <g id="znak_6A">
                            <polygon className="st9" points="474.3,423 446.4,423 446.4,420.4 446.4,407 474.3,407 474.3,420.4 	"/>
                            <rect x="446.4" y="419.5" className="st10" width="27.9" height="3.5"/>
                            <text transform="matrix(1 0 0 1 452.7427 417.3151)" className="st11 st7 st12">6.A</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6B" style={{textDecoration: "none"}}>
                        <g id="znak_6B">
                            <polygon className="st9" points="474.8,314.6 446.9,314.6 446.9,311.9 446.9,298.5 474.8,298.5 474.8,311.9 	"/>
                            <rect x="446.9" y="311.1" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.B" transform="matrix(1 0 0 1 453.215 308.8336)" className="st11 st7 st12">6.B</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6C" style={{textDecoration: "none"}}>
                        <g id="znak_6C">
                            <polygon className="st9" points="475.7,255.9 447.8,255.9 447.8,253.3 447.8,239.8 475.7,239.8 475.7,253.3 	"/>
                            <rect x="447.8" y="252.4" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.C" transform="matrix(1 0 0 1 454.125 250.1886)" className="st11 st7 st12">6.C</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6D" style={{textDecoration: "none"}}>
                        <g id="znak_6D">
                            <polygon className="st9" points="474.8,198.9 446.9,198.9 446.9,196.2 446.9,182.8 474.8,182.8 474.8,196.2 	"/>
                            <rect x="446.9" y="195.4" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.D" transform="matrix(1 0 0 1 453.215 193.1385)" className="st11 st7 st12">6.D</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6E" style={{textDecoration: "none"}}>
                        <g id="znak_6E">
                            <polygon className="st9" points="474.8,141.3 446.9,141.3 446.9,138.7 446.9,125.2 474.8,125.2 474.8,138.7 	"/>
                            <rect x="446.9" y="137.8" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.E" transform="matrix(1 0 0 1 453.215 135.5635)" className="st11 st7 st12">6.E</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6F" style={{textDecoration: "none"}}>
                        <g id="znak_6F">
                            <polygon className="st9" points="157.1,141.3 129.2,141.3 129.2,138.7 129.2,125.2 157.1,125.2 157.1,138.7 	"/>
                            <rect x="129.2" y="137.9" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.F" transform="matrix(1 0 0 1 135.5 135.5635)" className="st11 st7 st12">6.F</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6G" style={{textDecoration: "none"}}>
                        <g id="znak_6G">
                            <polygon className="st9" points="159.2,201.9 131.3,201.9 131.3,199.3 131.3,185.8 159.2,185.8 159.2,199.3 	"/>
                            <rect x="131.3" y="198.4" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.G" transform="matrix(1 0 0 1 137.625 196.1586)" className="st11 st7 st12">6.G</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6H" style={{textDecoration: "none"}}>
                        <g id="znak_6H">
                            <polygon className="st9" points="158.9,256.3 131,256.3 131,253.7 131,240.2 158.9,240.2 158.9,253.7 	"/>
                            <rect x="131" y="252.8" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.H" transform="matrix(1 0 0 1 137.34 250.5735)" className="st11 st7 st12">6.H</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6J" style={{textDecoration: "none"}}>
                        <g id="znak_6J">
                            <polygon className="st9" points="159.2,314.6 131.3,314.6 131.3,311.9 131.3,298.5 159.2,298.5 159.2,311.9 	"/>
                            <rect x="131.3" y="311.1" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.J" transform="matrix(1 0 0 1 137.625 308.8336)" className="st11 st7 st12">6.J</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6K" style={{textDecoration: "none"}}>
                        <g id="znak_6K">
                            <polygon className="st9" points="159.2,425.9 131.3,425.9 131.3,423.2 131.3,409.8 159.2,409.8 159.2,423.2 	"/>
                            <rect x="131.3" y="422.4" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.K" transform="matrix(1 0 0 1 137.625 420.15)" className="st11 st7 st12">6.K</text>
                        </g>
                    </NavLink>
                    <NavLink to="/graniczna/lokale/6L" style={{textDecoration: "none"}}>
                        <g id="znak_6L">
                            <polygon className="st9" points="159.2,481.1 131.3,481.1 131.3,478.5 131.3,465 159.2,465 159.2,478.5 	"/>
                            <rect x="131.3" y="477.6" className="st10" width="27.9" height="3.5"/>
                            <text id="_x36_.L" transform="matrix(1 0 0 1 137.625 475.3636)" className="st11 st7 st12">6.L</text>
                        </g>
                    </NavLink>
                </svg>
                <div style={{width: "20vw", height: "55vh"}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.0670706052088!2d20.923470776961345!3d52.387334446042935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eb912dfb60991%3A0x5cfe6ab2a1b71202!2sApartamenty%20Graniczna%206!5e0!3m2!1spl!2spl!4v1693517124171!5m2!1spl!2spl" style={{border:0, width: "18vw", height: "30vh", paddingRight: "2vw"}} allowFullScreen="true" loading="lazy" className={"center"} title={"granicznaPC"}/>
                </div>
            </div>
            <Footer/>
        </>

    );
}

}

export default Graniczna;

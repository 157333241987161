import React from 'react';
import Panel from "./repeated/Panel";
import Footer from "./repeated/Footer";
import {isMobile} from "react-device-detect";
import MobilePanel from "./repeated/MobilePanel";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const BigImage = () => {

    if (isMobile) {
        return (
            <>
                <div className="mBottom center" style={{paddingBottom: "15vh"}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={4000} showArrows={false} showStatus={false} showIndicators={false} autoFocus={true} width={"80vw"} emulateTouch={false} swipeable={false} stopOnHover={false}>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome1.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome2.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome3.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome4.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome5.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome6.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome7.webP"  alt="" className="represent-photo-large"/>
                        </div>
                    </Carousel>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="bottom-size center">
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={4000} showArrows={false} showStatus={false} showIndicators={false} autoFocus={true} width={"65vw"} emulateTouch={false} swipeable={false} stopOnHover={false}>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome1.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome2.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome3.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome4.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome5.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome6.webP"  alt="" className="represent-photo-large"/>
                        </div>
                        <div>
                            <img src="https://arkadiadevelopment.pl/img/welcome7.webP"  alt="" className="represent-photo-large"/>
                        </div>
                    </Carousel>
                </div>
            </>
        );
    }
}


const App = () => {

    if (isMobile) {
        return (
            <>
                <MobilePanel/>
                <BigImage/>
                <Footer/>
            </>
        )
    } else {
        document.getElementById("body").style.overflow = "hidden"
        return (
            <>
                <Panel/>
                <BigImage/>
                <Footer/>
            </>
        );
    }


};

export default App;
